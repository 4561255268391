import "./featuredproperties.css";

function FeaturedProperties() {
  return (
    <div className="fp">
      <div className="fpItem">
        <img
          src="https://cf.bstatic.com/xdata/images/hotel/max500/16379302.jpg?k=55494e13bb009658f0b7114e816e647c36c26851bf214b6fbc2f05558f9edf86&o="
          alt=""
          className="fpImg"
        />
        <span className="fpName">Villa Domina</span>
        <span className="fpCity">Split</span>
        <span className="fpPrice">Starting from US$58</span>
        <div className="fpRating">
          <button>9.4</button>
          <span>Superb</span>
        </div>
      </div>

      <div className="fpItem">
        <img
          src="https://cf.bstatic.com/xdata/images/hotel/max500/16379302.jpg?k=55494e13bb009658f0b7114e816e647c36c26851bf214b6fbc2f05558f9edf86&o="
          alt=""
          className="fpImg"
        />
        <span className="fpName">Villa Domina</span>
        <span className="fpCity">Split</span>
        <span className="fpPrice">Starting from US$58</span>
        <div className="fpRating">
          <button>9.4</button>
          <span>Superb</span>
        </div>
      </div>

      <div className="fpItem">
        <img
          src="https://cf.bstatic.com/xdata/images/hotel/max500/16379302.jpg?k=55494e13bb009658f0b7114e816e647c36c26851bf214b6fbc2f05558f9edf86&o="
          alt=""
          className="fpImg"
        />
        <span className="fpName">Villa Domina</span>
        <span className="fpCity">Split</span>
        <span className="fpPrice">Starting from US$58</span>
        <div className="fpRating">
          <button>9.4</button>
          <span>Superb</span>
        </div>
      </div>

      <div className="fpItem">
        <img
          src="https://cf.bstatic.com/xdata/images/hotel/max500/16379302.jpg?k=55494e13bb009658f0b7114e816e647c36c26851bf214b6fbc2f05558f9edf86&o="
          alt=""
          className="fpImg"
        />
        <span className="fpName">Villa Domina</span>
        <span className="fpCity">Split</span>
        <span className="fpPrice">Starting from US$58</span>
        <div className="fpRating">
          <button>9.4</button>
          <span>Superb</span>
        </div>
      </div>
    </div>
  );
}

export default FeaturedProperties;
