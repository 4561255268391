import PropertyList from "../../components/propertylist/PropertyList";
import Header from "../../components/header/Header";
import Navbar from "../../components/navbar/Navbar";
import "./home.css"
import Featured from "../../components/featured/Featured";
import FeaturedProperties from "../../components/featuredProperties/FeaturedProperties";
import MailList from "../../components/mailList/MailList";
import Footer from "../../components/footer/Footer";

function Home() {
  return (
    <>
     <Navbar />
     <Header />
     <div className="homeContainer">
      <Featured />
      <h1 className="homeTitle">Travel like a local</h1>
      <PropertyList />
      <h1 className="homeTitle">Homes guests love</h1>
      <FeaturedProperties />
      <MailList />
      <Footer />
     </div>
    </>
  )
}

export default Home;
