import React from "react";
import { Route, Routes } from "react-router-dom";
import Home  from "./pages/home/Home";
import Contact from "./pages/Contact";
import List from "./pages/list/List";
import Hotel from "./pages/hotel/Hotel";


function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/hotels" element={<List />} />
      <Route path="/hotels/:id" element={<Hotel />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
}

export default App;
