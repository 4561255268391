import './searchItem.css';

function SearchItem() {
  return (
    <div className='searchItem'> 
        <img src="https://cf.bstatic.com/xdata/images/hotel/square200/251036510.webp?k=2d440bcb4803d4b432b0a0747b7c5f2ca2bfcae8f82dfb425e20215bf04e2e61&o=&s=1" alt=""  className='siImg'/>
        <div className="siDesc">
            <h1 className="siTitle">The First Collection at Jumeirah Village Circle</h1>
            <span className="siDistance">500m from center</span>
            <span className="siTaxiOp">Free airport taxi</span>
            <span className="siSubtitle">Studio Apartment with air condition</span>
            <span className="siFeatures">Entire studio . 1 bathroom . 21m<sup>2</sup> 1 fulll bed</span>
            <span className="siCancelOp">Free cancellation</span>
            <span className="siCancelOpSubtitle">You can cancel later, so lock this great price today</span>
        </div>
        <div className="siDetails">
            <div className="siRating">
                <span>Excellent</span>
                <button>8.9</button>
            </div>
            <div className="siDetailTexts">
                <span className="siPrice">
                    $123
                </span>
                <span className="siTaxOp">Includes taxes and fees</span>
                <button className="siCheckButton">See availabilty</button>
            </div>
        </div>
    </div>
  )
}

export default SearchItem
