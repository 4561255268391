import "./featured.css"

function Featured() {
  return (
    <div className="featured">
      <div className="featuredItem">
        <img src="https://cf.bstatic.com/xdata/images/hotel/square600/286659200.webp?k=9206fc9239b3e4538c22d04b85213d6d5e6257015022de8a37effd956fcde4b6&o=&s=1" alt="" className="featuredImg" />
        <div className="featuredTitles">
            <h1>Sarajevo</h1>
            <h2>123 properites</h2>
        </div>
      </div>

      <div className="featuredItem">
        <img src="https://cf.bstatic.com/xdata/images/hotel/square600/132452060.webp?k=8448bab55c87dbb42ab7c6643fbbce542124c908f63f6b36dc3cdc859e60cb0a&o=&s=1" alt="" className="featuredImg" />
        <div className="featuredTitles">
            <h1>Mostar</h1>
            <h2>98 properites</h2>
        </div>
      </div>

      <div className="featuredItem">
        <img src="https://cf.bstatic.com/xdata/images/hotel/square600/187855604.webp?k=bbb45aa5b540c7608ea3af52d92b95a215df9af831dd3ae0e4c4cce501e28b1b&o=&s=1" alt="" className="featuredImg" />
        <div className="featuredTitles">
            <h1>Split</h1>
            <h2>110 properites</h2>
        </div>
      </div>
    </div>
  )
}

export default Featured
