import "./propertylist.css"

function PropertyList() {
  return (
    <div className="propertylist">
     <div className="propertyListItem">
        <img src="https://cf.bstatic.com/xdata/images/city/square250/972690.webp?k=c6a1a0ccda8e2ca5ac92c294eaadc0d00dfa20af772dab285ae237b482494942&o=" className="propertyListImg"/>
        <div className="propertyListTitles">
            <h3>Sarajevo</h3>
            <p>2300 Properties</p>
        </div>
     </div>

     <div className="propertyListItem">
        <img src="https://cf.bstatic.com/xdata/images/city/square250/638211.webp?k=3b446999bedeaaf7bdf63c009cb4a4b23089ca14783bb9be44b287016f36ca70&o=" className="propertyListImg"/>
        <div className="propertyListTitles">
            <h3>Mostar</h3>
            <p>659 Properties</p>
        </div>
     </div>

     <div className="propertyListItem">
        <img src="https://cf.bstatic.com/xdata/images/city/square250/671604.webp?k=d240dacfb0a3745d9891f1bab77d555c700fd7034b602a11373e340ed151bee2&o="className="propertyListImg" />
        <div className="propertyListTitles">
            <h3>Zoo de Beauval</h3>
            <p>297 Properties</p>
        </div>
     </div>

     <div className="propertyListItem">
        <img src="https://cf.bstatic.com/xdata/images/city/square250/734982.webp?k=534b8bf2329699a1a72703a1ad6481b5f473f9d875e0087b5fb24a57198dc85d&o=" className="propertyListImg"/>
        <div className="propertyListTitles">
            <h3>Banja Luka</h3>
            <p>521 Properties</p>
        </div>
     </div>

     <div className="propertyListItem">
        <img src="https://cf.bstatic.com/xdata/images/city/square250/942202.webp?k=691aed347787995b34ca6868f8f746ff9b1a57c9eb93fdc9cffffe4fec42d7cc&o=" className="propertyListImg"/>
        <div className="propertyListTitles">
            <h3>Vlasic</h3>
            <p>123 Properties</p>
        </div>
     </div>

     <div className="propertyListItem">
        <img src="https://cf.bstatic.com/xdata/images/city/square250/707243.webp?k=93609367e7eeddb8cc512462bebbb4533c894593a20e38e0807f8a23a7b8295e&o="className="propertyListImg" />
        <div className="propertyListTitles">
            <h3>Bjelašnica</h3>
            <p>57 Properties</p>
        </div>
     </div>
    </div>
  )
}

export default PropertyList
